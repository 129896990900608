import './scss/index.scss';

import '../../shared/data';

import 'slick-carousel';
// import 'bootstrap/js/tooltip';
// import * as moment from 'moment-timezone';
import {Search} from "../../shared/js/Search";
import {Listings} from "../../shared/js/Listings";
// import {MuiForm} from "../shared/components/mui-form/mui-form";


$(document).ready(function() {
    Search.init({
        autocomplete: false,
        redirect_link: '/listings',
        container: $('#new_search_module'),
        type: 'item-single',
        allowEmptySearch: false, // preventing listings load on empty search
    }, function() {
        // $('.header-search').show();
    });

    _data.rows = 8;
    _data.filters.page = 1;
    _data.filters.displays_to_non_members = true;
    Listings.init(false);
    Listings.setListingStyle('grid', $('.toggle-view-button.active')[0], false);
    Listings.setListingsSort('time');
    Listings.getListings(null, 8);
    /*_data.refresh(function() {
        Listings.setListingStyle('full', $('.toggle-view-button.active')[0], false);
        Listings.appendSingleItem(window._ltsData);
    }, false);*/

    // $('[data-toggle="tooltip"]').tooltip();

    const options = {
        infinite: false,
        centerMode: false,
        mobileFirst: true,
        prevArrow: '<button class="slick-prev" aria-label="Previous"><span class="fa fa-chevron-left"></span></button>',
        nextArrow: '<button class="slick-next" aria-label="Next"><span class="fa fa-chevron-right"></span></button>',
    };

    $('#category_plates').not('.slick-initialized').slick($.extend(options, {
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 4,
            }
        },{
            breakpoint: 650,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 4,
            }
        },{
            breakpoint: 500,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
            }
        },{
            breakpoint: 350,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        }]
    }));

    $('#manu_plates').not('.slick-initialized').slick($.extend(options, {
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [{
            breakpoint: 1199,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 3,
            }
        },{
            breakpoint: 991,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
            }
        },{
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        },{
            breakpoint: 500,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },{
            breakpoint: 350,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }]
    }));
    
});