import 'bootstrap';
import moment from "moment-timezone";
import {LISTINGS_CONTAINER_ID, SUBJECT_TYPE, TARGET_PRICE_TYPE} from "../modules/members/listings/create/js/constants";
import {addCommas, boldString, blueString, doTextHighlight, guid, url} from './utils';
import * as Solr from './js/Solr';

window._data = (function () {
    var exports = {};

    exports.LISTINGS_MOBILE_BREAKPOINT = 732;

    exports.filters = {
        page: 1
    };
    exports.load_more_count = 0;
    exports.rows = 50;
    exports.favorites = [];
    exports.container_id = LISTINGS_CONTAINER_ID;
    exports.paginate = true;
    exports.show_member_listings = false;
    exports.numFound = 0;
    exports.rewrite_url = false;
    exports.update_url = true;
    exports.hold_listing = null;

    exports.init = function () {

        if (typeof favorites !== 'undefined') {
            $.each(favorites, function (i, v) {
                _data.favorites.push(parseInt(v.tradefeed_id));
            });
        }

        if (typeof rewrite_history !== 'undefined') {
            if (rewrite_history) {
                _data.rewrite_url = rewrite_history;
            }
        }

        if (typeof init_filters !== 'undefined') {
            if (Object.keys(init_filters).length > 0) {
                _data.update_url = false;
            }
        }

        $('body').on('click', function (e) {
            $('[data-toggle=popover]').each(function () {
                // hide any open popovers when the anywhere else in the body is clicked
                if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                    $(this).popover('hide');
                }
            });
        });

        if (typeof init_producttype !== 'undefined') {
            //alert(Object.keys(init_producttype).length);
            if (Object.keys(init_producttype).length > 0) {
                var formatted_string = _data.get_formatted_string(init_producttype, false);
                $('#display_producttype').html('');
                $('#display_producttype').html(formatted_string);
            }
        }

        $(window).on('resize', function (event) {
            $('.item').each(function () {
                var gid = $(this).data('id');
                if ($(this).find('.item-title').height() > 25) {
                    $('.' + gid).find('.item-short-description').css({
                        'height': '42px',
                        '-webkit-line-clamp': '2',
                        'margin-top': '3px'
                    });
                } else {
                    $('.' + gid).find('.item-short-description').css({
                        'height': '60px',
                        '-webkit-line-clamp': '3'
                    });
                }
            });

        });

        $(window).on('popstate', function (event) {
            var doPopStateRefresh = true;

            if (typeof _no_popstate_reload_listings !== 'undefined') {
                doPopStateRefresh = false;
            }

            if(doPopStateRefresh){
                if (typeof event.originalEvent.state !== 'undefined') {
                    _data.update_url = false;

                    if (event.originalEvent.state !== null && typeof event.originalEvent.state.filters !== 'undefined') {
                        var filters = JSON.parse(event.originalEvent.state.filters);

                        _data.filters = filters;

                        if (typeof _data.filters.search === 'undefined' || _data.filters.search === '') {
                            $('.tl-search__input').val('');
                        } else {
                            $('.tl-search__input').val(_data.filters.search);
                        }
                    } else {
                        _data.filters = {
                            page: 1
                        };

                        $('.tl-search__input').val('');
                    }
                    
                    console.warn('popstate refresh');

                    _data.refresh(function () {
                        _data.dispatchPopulateFiltersEvent();
                        document.dispatchEvent(new CustomEvent('filter.populateSavedSearches', {detail: {sd: false,k: true}}));
                        document.dispatchEvent(new CustomEvent('listings.get', {}));
                    });

                } else {
                    if (typeof _data.filters.search === 'undefined' || _data.filters.search === '') {
                        $('.tl-search__input').val('');
                    } else {
                        $('.tl-search__input').val(_data.filters.search);
                    }
                }

            }

        });


        $('.show_more_records_from_company').click(function () {
            var count_total_records = $(this).attr("count_total_records");

            if (count_total_records >= 100) {
                $('.first_100_shown').show();
            }

            if ($('.show_more_records_from_company').hasClass('showing_more')) {
                $(".show_more_records").addClass('hidden');
                if (count_total_records >= 100) {
                    $('.first_100_shown').hide();
                }
                $('.show_more_records_from_company').removeClass('showing_more');

                $('.show_more_records_from_company').find('.more_or_less').html('more');

                window.Analytics.trackInGA('TradesCountBox-show-less',{});


            } else {
                $(".show_more_records").removeClass('hidden');
                if (count_total_records >= 100) {
                    $('.first_100_shown').show();
                }
                $('.show_more_records_from_company').addClass('showing_more');
                $('.show_more_records_from_company').find('.more_or_less').html('less');

                window.Analytics.trackInGA('TradesCountBox-show-more',{});
            }
        });


        // TODO: find a way to move top (pagination issue)
        if (typeof _paginate !== 'undefined') {
            _data.paginate = _paginate;
        }

        if (typeof _show_member_listings !== 'undefined' && _show_member_listings) {
            _data._show_member_listings = true;
        } else {
            _data._show_member_listings = false;
        }

        if (typeof _limit_to_data_only !== 'undefined' && _limit_to_data_only) {
            _data.limit_to_data = true;
        } else {
            _data.limit_to_data = false;
        }

        if (typeof _data_try_display_name_pops !== 'undefined' && ! _data_try_display_name_pops) {
            _data.use_display_name_popovers = false;
        } else {
            _data.use_display_name_popovers = true;
        }

        if (typeof init_filters === 'object') {
            $.extend(true, _data.filters, init_filters);

            if (_data.filters.hasOwnProperty('search')) {
                $('.tl-search__input').val(_data.filters.search);
            }
        }

        if (typeof rewrite_on_load !== 'undefined' && rewrite_on_load) {
            setTimeout(function() {
                history.pushState({}, "Tradeloop | The Wholesale Marketplace for Used Electronics", window.location.href.split('?')[0]);
            }, 3000);
        }

        const hash = window.location.hash.replace(/\#/gi, '');
        if (hash === 'login') {
            tradeloop.modal('login');
        }

        if (docPath.urlParams.has('page') && _data.paginate === true) {
            _data.filters.page = docPath.urlParams.get('page');
        } else {
            _data.filters.page = 1;
        }
    };

    exports.generateUrl = function (item, params) {
        if (item === null) {
            return;
        }

        var found_series_slug_in_model_slug = 0;
        var found_brand_slug_in_series_slug = 0;
        var found_series_slug_in_model_slug = 0;
        var found_brand_series_model_slug = 0
        var cat_tree = '';
        var modal_slug_string = '';
        var series_slug_string = '';

        if (params.endpoint === 'category_1') {
            if (typeof item.slug_cat_1 != 'undefined' && item.slug_cat_1.length > 0) {
                cat_tree += "/" + item.slug_cat_1;

                return cat_tree;
            }
        } else if (params.endpoint === 'category_2') {
            cat_tree += "/" + item.slug_cat_1;

            if (typeof item.slug_cat_2 != 'undefined' && item.slug_cat_2 != 'empty' && item.slug_cat_2.length > 0 && item.slug_cat_2 !== item.slug_cat_1) {
                cat_tree += "/" + item.slug_cat_2;
            }
            return cat_tree;
        }

        if (typeof item.slug_cat_1 != 'undefined' && item.slug_cat_1.length > 0) {
            cat_tree += "/" + item.slug_cat_1;

            if (typeof item.slug_cat_2 != 'undefined' && item.slug_cat_2 != 'empty' && item.slug_cat_2.length > 0 && item.slug_cat_2 !== item.slug_cat_1) {
                cat_tree += "/" + item.slug_cat_2;

                if (typeof item.slug_cat_3 != 'undefined' && item.slug_cat_3 != 'empty' && item.slug_cat_3.length > 0 && item.slug_cat_2 !== item.slug_cat_3) {
                    cat_tree += "/" + item.slug_cat_3;
                }

            } else {
                if (typeof item.slug_cat_3 != 'undefined' && item.slug_cat_3 != 'empty' && item.slug_cat_3.length > 0 && item.slug_cat_3 !== item.slug_cat_1) {
                    cat_tree += "/" + item.slug_cat_3;
                }
            }

        } else {
            if (typeof item.slug_cat_2 != 'undefined' && item.slug_cat_2 != 'empty' && item.slug_cat_2.length > 0 ) {
                cat_tree += "/" + item.slug_cat_2;

                if (typeof item.slug_cat_3 != 'undefined' && item.slug_cat_3 != 'empty' && item.slug_cat_3.length > 0 && item.slug_cat_2 !== item.slug_cat_3) {
                    cat_tree += "/" + item.slug_cat_3;
                }
            } else {
                if (typeof item.slug_cat_3 != 'undefined' && item.slug_cat_3 != 'empty' && item.slug_cat_3.length > 0) {
                    cat_tree += "/" + item.slug_cat_3;
                }
            }
        }

        if (params.endpoint === 'category') {
            return cat_tree;
        }


        if (typeof item.slug_manufacturer != 'undefined' && item.slug_manufacturer.length > 0) {
            cat_tree += "/" + item.slug_manufacturer;
        }

        if (params.endpoint === 'manufacturer') {
            return cat_tree;
        }

        if (typeof item.slug_model_type != 'undefined' && typeof item.slug_model != 'undefined' && item.slug_model_type.length > 0 && item.slug_model.length > 0) {
            modal_slug_string = item.slug_model_type;
            if (modal_slug_string.includes(item.slug_model) == false) {
                found_series_slug_in_model_slug = 0;
            } else {
                found_series_slug_in_model_slug = 1;
            }
        }//if(typeof item.slug_model_type != 'undefined' && typeof item.slug_model != 'undefined' && item.slug_model_type.length > 0 && item.slug_model.length > 0){


        if (typeof item.slug_brand != 'undefined' && typeof item.slug_model != 'undefined' && item.slug_brand.length > 0 && item.slug_model.length > 0) {
            series_slug_string = item.slug_model;
            if (series_slug_string.includes(item.slug_brand) == false) {
                found_brand_slug_in_series_slug = 0;
            } else {
                found_brand_slug_in_series_slug = 1;
            }
        }//if(typeof item.slug_brand != 'undefined' && typeof item.slug_model != 'undefined' && item.slug_brand.length > 0 && item.slug_model.length > 0){

        if (params.endpoint === 'model') {


            if (typeof item.slug_brand != 'undefined' && typeof item.slug_model != 'undefined' && item.slug_model.length > 0 && item.slug_brand.length > 0) {
                found_brand_series_model_slug = 1;

                if (found_brand_slug_in_series_slug == 0) {
                    if (found_series_slug_in_model_slug == 0) {

                        if (typeof item.slug_model_type != 'undefined' && item.slug_model_type.length > 0) {
                            cat_tree += "/" + item.slug_brand + "/" + item.slug_model + "/" + item.slug_model_type;
                        } else {
                            cat_tree += "/" + item.slug_brand + "/" + item.slug_model;
                        }//if(typeof item.slug_model_type != 'undefined' && item.slug_model_type.length > 0){
                    } else {
                        cat_tree += "/" + item.slug_brand + "/" + item.slug_model;
                    }//if(found_series_slug_in_model_slug == 0){
                } else {
                    if (found_series_slug_in_model_slug == 0) {
                        if (typeof item.slug_model_type != 'undefined' && item.slug_model_type.length > 0) {
                            cat_tree += "/" + item.slug_model + "/" + item.slug_model_type;
                        } else {
                            cat_tree += "/" + item.slug_model;
                        }
                    } else {
                        cat_tree += "/" + item.slug_model + "/" + item.slug_model_type;
                    }
                }//if(found_brand_slug_in_series_slug == 0){
            }//if(typeof item.slug_brand != 'undefined' && typeof item.slug_model != 'undefined' && item.slug_model.length > 0 && item.slug_brand.length > 0){

            return cat_tree;
        } else if (params.endpoint === 'series') {
            if (typeof item.slug_brand != 'undefined' && typeof item.slug_model != 'undefined' && item.slug_model.length > 0 && item.slug_brand.length > 0) {
                found_brand_series_model_slug = 1;

                if (found_brand_slug_in_series_slug == 0) {
                    cat_tree += "/" + item.slug_brand + "/" + item.slug_model;
                } else {
                    cat_tree += "/" + item.slug_model;
                }//if(found_brand_slug_in_series_slug == 0){
            }//if(typeof item.slug_brand != 'undefined' && typeof item.slug_model != 'undefined' && item.slug_model.length > 0 && item.slug_brand.length > 0){

            return cat_tree;
        } else if (params.endpoint === 'part') {
            if (typeof item.slug_brand != 'undefined' && typeof item.slug_model != 'undefined' && item.slug_model.length > 0 && item.slug_brand.length > 0) {
                found_brand_series_model_slug = 1;

                if (found_brand_slug_in_series_slug == 0) {
                    cat_tree += "/" + item.slug_brand + "/" + item.slug_model;
                } else {
                    cat_tree += "/" + item.slug_model;
                }//if(found_brand_slug_in_series_slug == 0){
            }//if(typeof item.slug_brand != 'undefined' && typeof item.slug_model != 'undefined' && item.slug_model.length > 0 && item.slug_brand.length > 0){

            if (item.part_clean) {
                cat_tree += "/" + item.part_clean;
            }

            return cat_tree;
        } else if (params.endpoint === 'brand') {
            if (typeof item.slug_brand != 'undefined' && item.slug_brand.length > 0) {
                cat_tree += "/" + item.slug_brand;
            }
            return cat_tree;
        }
    };


    exports.autosuggest = function (q) {
        var filter_str = [];
        var enq = encodeURI(q);
        q = q.trim();
        var qorq = "(*" + q + "* OR " + q + ")";
        var qpart = q.replace(/[^A-Za-z0-9]/gi, '');
        qpart = "(*" + qpart + "* OR " + qpart + ")";

        var qorqq = "(*" + q + "* OR \"" + q + "\")";
        var qind = "\"" + q + "\"";
        var list = $('.autocomplete-box');
        list.show();
        list.html('');

        if (typeof category_tree != 'undefined') {
            var at_least_one = false;

            $.each(category_tree, function (id, category) {
                if (id != "categories") {
                    var mega_str = '';
                    var cat_1_title = '<a href="javascript:void(0);" class="cat-ac-select" data-id="' + id + '" data-text="' + category.title + '" data-field="cat1">' + blueString(boldString(category.title, q.toLowerCase()), q.toLowerCase(), category.title) + '</a>';
                    var cat_1_shown = false;
                    var cat_1_added = false;
                    if (category.hasOwnProperty('title') && category.title != null && category.title.toLowerCase().indexOf(q.toLowerCase()) != -1) {
                        cat_1_shown = true;
                        at_least_one = true;
                    } else if (category.hasOwnProperty('slug') && category.slug != null && category.slug.toLowerCase().indexOf(q.toLowerCase()) != -1) {
                        cat_1_shown = true;
                        at_least_one = true;
                    }

                    $.each(category.subcategories, function (c2id, subcategory) {
                        var cat_2_title = '<a href="javascript:void(0);" class="cat-ac-select" data-text="' + category.title + '" data-id="' + c2id + '" data-field="cat2" data-c1id="' + id + '">' + blueString(boldString(subcategory.title, q.toLowerCase()), q.toLowerCase(), subcategory.title) + '</a>';
                        var cat_2_shown = false;
                        var cat_2_added = false;
                        if (subcategory.hasOwnProperty('title') && subcategory.title != null && subcategory.title.toLowerCase().indexOf(q.toLowerCase()) != -1) {
                            var str = '';
                            if (cat_1_shown) {
                                str = '&nbsp; - &nbsp;' + cat_2_title;
                            } else {
                                str = cat_1_title + ' > ' + cat_2_title;
                            }
                            cat_2_shown = true;
                            at_least_one = true;
                        } else if (subcategory.hasOwnProperty('slug') && subcategory.slug != null && subcategory.slug.toLowerCase().indexOf(q.toLowerCase()) != -1) {
                            var str = '';
                            if (cat_1_shown) {
                                str = '&nbsp; - &nbsp;' + cat_2_title;
                            } else {
                                str = cat_1_title + ' > ' + cat_2_title;
                            }
                            cat_2_shown = true;
                            at_least_one = true;
                        }
                        var one_cat_3 = false;
                        $.each(subcategory.subcategories, function (c3id, category3) {
                            if (q.length > 1) {
                                var cat_3_title = '<a href="javascript:void(0);" class="cat-ac-select" data-text="' + category.title + '" data-id="' + c3id + '" data-field="cat3" data-c1id="' + id + '"  data-c2id="' + c2id + '">' + blueString(boldString(category3.title, q.toLowerCase()), q.toLowerCase(), category3.title) + '</a>';
                                if (category3.hasOwnProperty('title') && category3.title != null && category3.title.toLowerCase().indexOf(q.toLowerCase()) != -1) {
                                    var str = '';
                                    if (Object.keys(category.subcategories).length === 1 && Object.keys(subcategory.subcategories).length === 1) {
                                        mega_str += '<div class="p-t-5 p-b-5">' + cat_3_title + '</div>';
                                    } else if (Object.keys(subcategory.subcategories).length === 1) {
                                        mega_str += '<div class="p-t-5 p-b-5">' + cat_1_title + ' &nbsp; > &nbsp; ' + cat_3_title + '</div>';
                                    } else {
                                        mega_str += '<div class="p-t-5 p-b-5">' + cat_1_title + ' &nbsp; > &nbsp; ' + cat_2_title + ' &nbsp; > &nbsp; ' + cat_3_title + '</div>';
                                    }
                                    if (cat_1_shown) {

                                        if (cat_2_shown) {
                                            str = '&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;' + cat_3_title;
                                        } else {
                                            str = '&nbsp; - &nbsp;' + cat_2_title + ' > ' + cat_3_title;
                                        }
                                    } else {
                                        str = cat_1_title + ' > ' + cat_2_title + ' > ' + cat_3_title;
                                    }
                                    //_data.add_row(c3id, str,'cat-ac-filter');
                                    at_least_one = true;
                                    one_cat_3 = true;
                                } else if (category3.hasOwnProperty('slug') && category3.slug != null && category3.slug.toLowerCase().indexOf(q.toLowerCase()) != -1) {
                                    var str = '';
                                    if (Object.keys(category.subcategories).length === 1 && Object.keys(subcategory.subcategories).length === 1) {
                                        mega_str += '<div class="p-t-5 p-b-5">' + cat_3_title + '</div>';
                                    } else if (Object.keys(subcategory.subcategories).length === 1) {
                                        mega_str += '<div class="p-t-5 p-b-5">' + cat_1_title + ' &nbsp; > &nbsp; ' + cat_3_title + '</div>';
                                    } else {
                                        mega_str += '<div class="p-t-5 p-b-5">' + cat_1_title + ' &nbsp; > &nbsp; ' + cat_2_title + ' &nbsp; > &nbsp; ' + cat_3_title + '</div>';
                                    }
                                    if (cat_1_shown) {

                                        if (cat_2_shown) {
                                            str = '&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;' + cat_3_title;
                                        } else {
                                            str = '&nbsp; - &nbsp;' + cat_2_title + ' > ' + cat_3_title;
                                        }
                                    } else {
                                        str = cat_1_title + ' > ' + cat_2_title + ' > ' + cat_3_title;
                                    }
                                    //_data.add_row(c3id, str,'cat-ac-filter');
                                    at_least_one = true;
                                    one_cat_3 = true;
                                }
                            }

                        });
                        if (!one_cat_3) {
                            if (cat_2_shown && !cat_2_added) {
                                cat_2_added = true;
                                mega_str += '<div class="p-t-5 p-b-5">' + cat_1_title + ' &nbsp; > &nbsp; ' + cat_2_title + '</div>';
                            } else if (cat_1_shown && !cat_1_added) {
                                cat_1_added = true;
                                mega_str += '<div class="p-t-5 p-b-5">' + cat_1_title + '</div>';
                            }
                        }
                    });
                    if (mega_str != '') {
                        mega_str = '<li class="cat-ac-filter">' + mega_str + '</li>';
                        list.append(mega_str);
                    }
                }
            });

            var listitems = list.children('li').get();
            listitems.sort(function (a, b) {
                var atext = $(a).text() + '';
                var btext = $(b).text() + ''
                return atext.toUpperCase().localeCompare(btext.toUpperCase());
            });
            $.each(listitems, function (idx, itm) {
                list.append(itm);
            });

            if (at_least_one) {
                list.find('.cat-ac-filter:first').before('<li class="section-break">Categories</li>');
                $('.cat-ac-select').click(function () {
                    if ($('#logged_out_search').length > 0) {
                        $('html,body').animate({scrollTop: $('#logged_out_search').offset().top}, 100);
                    }
                    _data.filters = {
                        page: 1
                    };
                    var id = $(this).data('id');
                    var cat_name = $(this).data('text');

                    var field = $(this).data('field');

                    var aStr = '';
                    _data.filters.categories = [];
                    if (field == "cat1") {
                        _data.filters.categories.push({
                            "id": id,
                            "type": "cat_1_id",
                            "name": cat_name,
                            "sub_categories": []
                        });
                        aStr = "&cat1id=" + id;
                    } else if (field == "cat2") {
                        var c1id = $(this).data('c1id');

                        _data.filters.categories.push({
                            "id": c1id,
                            "type": "cat_1_id",
                            "sub_categories": [{
                                "id": id,
                                "type": "cat_2_id",
                                "sub_categories": []
                            }]
                        });
                        aStr = "&cat1id=" + c1id + '&cat2id=' + id;
                    } else if (field == "cat3") {
                        var c1id = $(this).data('c1id');
                        var c2id = $(this).data('c2id');

                        _data.filters.categories.push({
                            "id": c1id,
                            "type": "cat_1_id",
                            "sub_categories": [{
                                "id": c2id,
                                "type": "cat_2_id",
                                "sub_categories": [{
                                    "id": id,
                                    "type": "cat_3_id"
                                }]
                            }]
                        });

                        aStr = "&cat1id=" + c1id + '&cat2id=' + c2id + '&cat3id=' + id;
                    }
                    list.hide();
                    $('.tl-search__input').val('');
                    $('.tl-search__clear').hide();

                    window.Analytics.trackInGA('filter-listings',{
                        type: 'category',
                        category_id: id,
                        category_level: field
                    });

                    _data.refresh(function() {
                        _data.dispatchPopulateFiltersEvent();
                        document.dispatchEvent(new CustomEvent('listings.get', {}));
                    });
                })
            }

        }


        list.append('<li class="autocomplete-loading text-center"><i class="fas fa-cog fa-spin"></i></li>');
        list.append('<li class="section-break extra-ac mfg-title hidden">Manufacturers</li>');
        list.append('<li class="section-break extra-ac part-title hidden">Manufacturer, Series, Models etc.</li>');
        list.append('<li class="section-break extra-ac trades-title hidden">Part Numbers</li>');


        var xxid = guid();
        _data.auto_search_id = xxid;

        filter_str.push("core:part");
        filter_str.push("is_hidden:false");
        if (_data._show_member_listings) {
            filter_str.push("displays_to_members:true");
        } else {
            filter_str.push("displays_to_non_members:true");
        }
        var query = Solr.Model.searchRaw('part_clean:' + qpart);
        if(filter_str) {
            filter_str.forEach((str)=>{
                query.whereRaw(str);
            });
        }
        query
            .groupBy('manu_id')
            .orderBy('part_clean','ASC')
            .get(5,1).then((r)=>{
                if (r) {

                    list.show();
                    list.find('.trade-filter').remove();

                    list.find('.autocomplete-loading').remove();
                    if (r.grouped.hasOwnProperty('manu_id') && r.grouped.manu_id.groups.length > 0) {
                        list.find('.trades-title').removeClass('hidden');
                        list.find('.trades-title').after('<li class="selectable extra-ac trade-filter trade-search ' + (r.grouped.manu_id.groups.length <= 1 ? 'hidden' : '') + ' " data-part="' + q + '">Show more part numbers containing "<b><span class="tl-color--tertiary">' + q + '</span></b>".</li>');
                    }

                    if (r.grouped.hasOwnProperty('manu_id') && r.grouped.manu_id.groups.length > 0) {

                        $.each(r.grouped.manu_id.groups, function (i, obj) {
                            if (obj.doclist.docs.length > 0) {
                                var v = obj.doclist.docs[0];
                                if (v.hasOwnProperty('manu_id') && v.manu_id !== 0 && typeof v.manufacturer_name != 'undefined') {
                                    list.find('.trade-search').before('<li class="selectable extra-ac trade-filter" data-manu_id="' + v.manu_id + '" data-part="' + v.part_number + '">' + v.manufacturer_name + ' Part #: <span class="tl-color--tertiary">' + boldString(v.part_number, q.toLowerCase()) + '</span></li>');
                                }
                            }
                        });
                    }

                    var listitems = list.children('li.trade-filter').get();
                    listitems.sort(function (a, b) {
                        var atext = $(a).data('part') + '';
                        var btext = $(b).data('part') + '';
                        return atext.toUpperCase().localeCompare(btext.toUpperCase());
                    });
                    $.each(listitems, function (idx, itm) {
                        list.find('.trade-search').before(itm);
                    });

                    if ($('.trade-filter').length == 0) {
                        list.find('.trades-title').addClass('hidden');
                    }

                    $('.trade-filter').click(function () {
                        if ($('#logged_out_search').length > 0) {
                            $('html,body').animate({scrollTop: $('#logged_out_search').offset().top}, 100);
                        }
                        _data.remove_saved_search();

                        if ($(this).hasClass('trade-search')) {
                            var part = $(this).data('part') + '';
                            delete _data.filters.manufacturer_ids;
                            _data.filters.search_trades = true;
                            _data.filters.search = part;
                            _data.filters.show_parts = 1;

                            list.hide();
                            $('.tl-search__input').val(part);
                            $('.tl-search__clear').hide();

                            window.Analytics.trackInGA('Autocomplete-Query-Clicked',{
                                query: q,
                                type: 'part'
                            });

                            _data.refresh(function() {
                                _data.dispatchPopulateFiltersEvent();
                                document.dispatchEvent(new CustomEvent('listings.get', {}));
                            });

                        } else {
                            _data.filters.search_trades = false;
                            var part = $(this).data('part') + '';
                            var manu_id = $(this).data('manu_id');
                            _data.filters.manufacturer_ids = [manu_id];
                            _data.filters.search = part;
                            _data.filters.show_parts = 1;

                            list.hide();
                            $('.tl-search__input').val(part);
                            $('.tl-search__clear').hide();

                            window.Analytics.trackInGA('Autocomplete-Query-Clicked',{
                                query: part,
                                type: 'part'
                            });
                            _data.refresh(function() {
                                _data.dispatchPopulateFiltersEvent();
                                document.dispatchEvent(new CustomEvent('listings.get', {}));
                            });
                        }


                    });
                }
            });

        var query2 = Solr.Model.searchRaw('bmmtSearch:' + qpart);

        query2
            .where('core','listing')
            .select(['cat1id', 'cat2id', 'cat3id', 'cat2', 'cat3', 'brand_name', 'brand_id', 'manu_id', 'manufacturer_name', 'model_name', 'model_id', 'type_number', 'catalog_model_types_id'])
            .groupBy('manu_id')
            .orderBy('part_clean','ASC')
            .get(20,1).then((r)=>{
                if (r) {

                    list.show();


                    list.find('.part-ac-filter').remove();

                    list.find('.autocomplete-loading').remove();

                    if (r.grouped.hasOwnProperty('type_number') && r.grouped.type_number.groups.length > 0) {

                        list.find('.part-title').removeClass('hidden');
                        var is_manu_set = false;
                        var is_brand_set = false;
                        var is_series_set = false;
                        $.each(r.grouped.type_number.groups, function (i, v) {

                            if (v.doclist.docs.length > 0) {
                                var obj = v.doclist.docs[0];
                                var category = '';
                                if (obj.hasOwnProperty('cat3') && obj.cat3 != null && obj.cat3 != 'empty') {
                                    category = obj.cat3;
                                } else if (obj.hasOwnProperty('cat2') && obj.cat2 != null) {
                                    category = obj.cat2;
                                }
                                if (typeof obj.manufacturer_name == 'undefined' || obj.manu_id == 0) {
                                    obj.manufacturer_name = '';
                                }


                                if (!is_series_set && typeof obj.model_name != 'undefined' && obj.model_name.toLowerCase() == q.toLowerCase()) {
                                    list.find('.part-title').after('<li class="non-selectable extra-ac part-ac-filter" data-id="' + obj.catalog_model_types_id + '" data-model="' + obj.model_id + '" data-brand="' + obj.brand_id + '" data-mfg="' + obj.manuid + '" data-cat1id="' + obj.cat1id + '" data-cat2id="' + obj.cat2id + '" data-cat3id="' + obj.cat3id + '"><a class="mfg-part-filter tl-color--tertiary"><span class="tl-color--tertiary">' + boldString(obj.manufacturer_name, q.toLowerCase()) + '</span></a> <a class="tl-color--tertiary brand-part-filter ">' + (obj.brand_name != null && obj.brand_name != '' ? '&bull; <span class="tl-color--tertiary">' + boldString(obj.brand_name, q.toLowerCase()) + '</span>' : '') + '</a> <a class="tl-color--tertiary model-part-filter ">' + (obj.model_name != null && obj.model_name != '' ? '&bull; <span class="tl-color--tertiary">' + boldString(obj.model_name, q.toLowerCase()) + '</span>' : '') + '</a> &nbsp; <i class=""><small>' + category.toLowerCase() + '</small></i></li>');
                                    is_series_set = true;
                                } else if (!is_brand_set && typeof obj.brand_name != 'undefined' && obj.brand_name.toLowerCase() == q.toLowerCase()) {

                                    list.find('.part-title').after('<li class="non-selectable extra-ac part-ac-filter" data-id="' + obj.catalog_model_types_id + '" data-model="' + obj.model_id + '" data-brand="' + obj.brand_id + '" data-mfg="' + obj.manuid + '" data-cat1id="' + obj.cat1id + '" data-cat2id="' + obj.cat2id + '" data-cat3id="' + obj.cat3id + '"><a class="mfg-part-filter tl-color--tertiary"><span class="tl-color--tertiary">' + boldString(obj.manufacturer_name, q.toLowerCase()) + '</span></a> <a class="tl-color--tertiary brand-part-filter ">' + (obj.brand_name != null && obj.brand_name != '' ? '&bull; <span class="tl-color--tertiary">' + boldString(obj.brand_name, q.toLowerCase()) + '</span>' : '') + '</a> &nbsp; <i class=""><small>' + category.toLowerCase() + '</small></i></li>');
                                    is_brand_set = true;
                                } else if (!is_manu_set && typeof obj.manufacturer_name != 'undefined' && obj.manufacturer_name.toLowerCase() == q.toLowerCase()) {
                                    list.find('.part-title').after('<li class="non-selectable extra-ac part-ac-filter" data-id="' + obj.catalog_model_types_id + '" data-model="' + obj.model_id + '" data-brand="' + obj.brand_id + '" data-mfg="' + obj.manu_id + '" data-cat1id="' + obj.cat1id + '" data-cat2id="' + obj.cat2id + '" data-cat3id="' + obj.cat3id + '"><a class="mfg-part-filter tl-color--tertiary"><span class="tl-color--tertiary">' + boldString(obj.manufacturer_name, q.toLowerCase()) + '</span></a></li>');
                                    is_manu_set = true;
                                } else if (!is_manu_set && !is_brand_set &&
                                    ((typeof obj.type_number != 'undefined' && obj.type_number.toLowerCase().indexOf(q.toLowerCase()) != -1) ||
                                        (typeof obj.manufacturer_name != 'undefined' && obj.manufacturer_name.toLowerCase().indexOf(q.toLowerCase()) != -1) ||
                                        (typeof obj.brand_name != 'undefined' && obj.brand_name.toLowerCase().indexOf(q.toLowerCase()) != -1) ||
                                        (typeof obj.model_name != 'undefined' && obj.model_name.toLowerCase().indexOf(q.toLowerCase()) != -1))
                                ) {
                                    list.find('.part-title').after('<li class="non-selectable extra-ac part-ac-filter" data-id="' + obj.catalog_model_types_id + '" data-model="' + obj.model_id + '" data-brand="' + obj.brand_id + '" data-mfg="' + obj.manu_id + '" data-cat1id="' + obj.cat1id + '" data-cat2id="' + obj.cat2id + '" data-cat3id="' + obj.cat3id + '"><a class="mfg-part-filter tl-color--tertiary"><span class="tl-color--tertiary">' + boldString(obj.manufacturer_name, q.toLowerCase()) + '</span></a> <a class="tl-color--tertiary brand-part-filter ">' + (obj.brand_name != null && obj.brand_name != '' ? '&bull; <span class="tl-color--tertiary">' + boldString(obj.brand_name, q.toLowerCase()) + '</span>' : '') + '</a> <a class="tl-color--tertiary model-part-filter ">' + (obj.model_name != null && obj.model_name != '' ? '&bull; <span class="tl-color--tertiary">' + boldString(obj.model_name, q.toLowerCase()) + '</span>' : '') + '</a> <a class="part-part-filter">' + (obj.type_number != null && obj.type_number != '' ? '&bull; <span class="tl-color--tertiary">' + boldString(obj.type_number, q.toLowerCase()) + '</span>' : '') + '</a> &nbsp; <i class=""><small>' + category.toLowerCase() + '</small></i></li>');
                                }
                            }


                        });

                        if (list.find('.part-ac-filter').length == 0) {
                            list.find('.part-title').addClass('hidden');
                        }

                        $('.mfg-part-filter').click(function () {
                            if ($('#logged_out_search').length > 0) {

                                $('html,body').animate({scrollTop: $('#logged_out_search').offset().top}, 100);
                            }
                            _data.remove_saved_search();
                            _data.filters = {
                                page: 1
                            };
                            var mid = $(this).parent().data('mfg');
                            _data.filters.manufacturer_ids = [];
                            _data.filters.manufacturer_ids.push(mid);

                            list.hide();
                            $('.tl-search__input').val('');
                            $('.tl-search__clear').hide();

                            window.Analytics.trackInGA('Autocomplete-Query-Clicked',{
                                query: q,
                                type: 'manufacturer'
                            });

                            _data.refresh(function() {
                                _data.dispatchPopulateFiltersEvent();
                                document.dispatchEvent(new CustomEvent('listings.get', {}));
                            });
                        });

                        $('.brand-part-filter').click(function () {
                            if ($('#logged_out_search').length > 0) {
                                $('html,body').animate({scrollTop: $('#logged_out_search').offset().top}, 100);
                            }
                            _data.remove_saved_search();
                            _data.filters = {
                                page: 1
                            };

                            var bid = $(this).parent().data('brand');
                            _data.filters.brand_ids = [];
                            _data.filters.brand_ids.push(bid);
                            var mid = $(this).parent().data('mfg');
                            _data.filters.manufacturer_ids = [];
                            _data.filters.manufacturer_ids.push(mid);
                            _data.filters.categories = [];
                            var c3id = $(this).parent().data('cat3id');
                            var c2id = $(this).parent().data('cat2id');
                            var c1id = $(this).parent().data('cat1id');

                            if (c1id != 'undefined' && c1id != '') {

                                var d = {
                                    "id": c1id,
                                    "type": "cat_1_id",
                                    "sub_categories": []
                                };

                                if (c2id != 'undefined' && c2id != '') {

                                    d.sub_categories.push({
                                        "id": c2id,
                                        "type": "cat_2_id",
                                        "sub_categories": []
                                    });

                                    if (c3id != 'undefined' && c3id != '') {
                                        d.sub_categories[0].sub_categories.push({
                                            "id": c3id,
                                            "type": "cat_3_id"
                                        });
                                    }
                                }
                                _data.filters.categories.push(d);

                            }

                            list.hide();
                            $('.tl-search__input').val('');
                            $('.tl-search__clear').hide();

                            window.Analytics.trackInGA('Autocomplete-Query-Clicked',{
                                query: q,
                                type: 'brand'
                            });

                            _data.refresh(function() {
                                _data.dispatchPopulateFiltersEvent();
                                document.dispatchEvent(new CustomEvent('listings.get', {}));
                            });
                        });

                        $('.model-part-filter').click(function () {
                            if ($('#logged_out_search').length > 0) {
                                $('html,body').animate({scrollTop: $('#logged_out_search').offset().top}, 100);
                            }
                            _data.remove_saved_search();
                            _data.filters = {
                                page: 1
                            };

                            var moid = $(this).parent().data('model');
                            _data.filters.model_ids = [];
                            _data.filters.model_ids.push(moid);
                            var bid = $(this).parent().data('brand');
                            _data.filters.brand_ids = [];
                            _data.filters.brand_ids.push(bid);
                            var mid = $(this).parent().data('mfg');
                            _data.filters.manufacturer_ids = [];
                            _data.filters.manufacturer_ids.push(mid);
                            _data.filters.categories = [];
                            var c3id = $(this).parent().data('cat3id');
                            var c2id = $(this).parent().data('cat2id');
                            var c1id = $(this).parent().data('cat1id');

                            if (c1id != 'undefined' && c1id != '') {

                                var d = {
                                    "id": c1id,
                                    "type": "cat_1_id",
                                    "sub_categories": []
                                };

                                if (c2id != 'undefined' && c2id != '') {

                                    d.sub_categories.push({
                                        "id": c2id,
                                        "type": "cat_2_id",
                                        "sub_categories": []
                                    });

                                    if (c3id != 'undefined' && c3id != '') {
                                        d.sub_categories[0].sub_categories.push({
                                            "id": c3id,
                                            "type": "cat_3_id"
                                        });
                                    }
                                }
                                _data.filters.categories.push(d);

                            }

                            list.hide();
                            $('.tl-search__input').val('');
                            $('.tl-search__clear').hide();

                            window.Analytics.trackInGA('Autocomplete-Query-Clicked',{
                                query: q,
                                type: 'brand'
                            });

                            _data.refresh(function() {
                                _data.dispatchPopulateFiltersEvent();
                                document.dispatchEvent(new CustomEvent('listings.get', {}));
                            });
                        });

                        $('.part-part-filter').click(function () {
                            if ($('#logged_out_search').length > 0) {
                                $('html,body').animate({scrollTop: $('#logged_out_search').offset().top}, 100);
                            }
                            _data.remove_saved_search();
                            _data.filters = {
                                page: 1
                            };
                            var id = $(this).parent().data('id');
                            _data.filters.type_ids = [];
                            _data.filters.type_ids.push(id);
                            var moid = $(this).parent().data('model');
                            _data.filters.model_ids = [];
                            _data.filters.model_ids.push(moid);
                            var bid = $(this).parent().data('brand');
                            _data.filters.brand_ids = [];
                            _data.filters.brand_ids.push(bid);
                            var mid = $(this).parent().data('mfg');
                            _data.filters.manufacturer_ids = [];
                            _data.filters.manufacturer_ids.push(mid);
                            _data.filters.categories = [];
                            var c3id = $(this).parent().data('cat3id');
                            var c2id = $(this).parent().data('cat2id');
                            var c1id = $(this).parent().data('cat1id');

                            if (c1id != 'undefined' && c1id != '') {

                                var d = {
                                    "id": c1id,
                                    "type": "cat_1_id",
                                    "sub_categories": []
                                };

                                if (c2id != 'undefined' && c2id != '') {

                                    d.sub_categories.push({
                                        "id": c2id,
                                        "type": "cat_2_id",
                                        "sub_categories": []
                                    });

                                    if (c3id != 'undefined' && c3id != '') {
                                        d.sub_categories[0].sub_categories.push({
                                            "id": c3id,
                                            "type": "cat_3_id"
                                        });
                                    }
                                }
                                _data.filters.categories.push(d);

                            }

                            list.hide();
                            $('.tl-search__input').val('');
                            $('.tl-search__clear').hide();

                            window.Analytics.trackInGA('Autocomplete-Query-Clicked',{
                                query: q,
                                type: 'series'
                            });
                            _data.refresh(function() {
                                _data.dispatchPopulateFiltersEvent();
                                document.dispatchEvent(new CustomEvent('listings.get', {}));
                            });
                        });

                    } else {
                        list.find('.part-title').addClass('hidden');

                    }

                }

            });



    };

    exports.get_formatted_string = function (item, show_ships_from) {
        var formatted_string = '';
        var features_separator = '<span class=\"listing__separator\"> &bull; </span>';
        var regexp = /^[A-Z]/;

        if (item.manu_id !== 0 && item.manu_id !== -1 && item.manufacturer_name && item.manufacturer_name.length > 0) {

            var manuStr = '';
            if (regexp.test(item.slug_manufacturer)) {
                var get_slugmanufacturer = item.slug_manufacturer;
                get_slugmanufacturer = get_slugmanufacturer.toString().replace(/\-/g, " ");
                manuStr += get_slugmanufacturer + " ";
            } else {
                manuStr += item.manufacturer_name + " ";
            }

            if (item.cat3 != null && item.cat3 !== "" && item.cat3 !== "empty") {
                manuStr += item.cat3 + " ";
                formatted_string += manuStr;
            }

        } else {
            if (item.cat3 != null && item.cat3 !== "" && item.cat3 !== "empty") {
                formatted_string += item.cat3 + " ";
            } else if (item.cat2 != null && item.cat2 !== "") {
                formatted_string += item.cat2 + " ";
            } else if (item.cat1 != null && item.cat1 !== "") {
                formatted_string += item.cat1 + " ";
            }
        }


        if (item.brand_name != null && item.brand_name !== "") {
            formatted_string += features_separator + item.brand_name;
        }

        if (item.model_name != null && item.model_name !== "") {
            formatted_string += features_separator + item.model_name;
        }

        if (item.hasOwnProperty('type_number') && item.type_number != null && item.type_number !== "") {
            formatted_string += features_separator + item.type_number;
        }

        if (show_ships_from) {
            if (typeof item.ships_from_country != 'undefined' && item.ships_from_country.length > 0) {
                formatted_string += ' <small class="tl-text--muted">' + (item.subject_typeid === SUBJECT_TYPE.wtb ? 'destination ' : 'ships from ') + item.ships_from_country + '</small> ';
            }
        }

        return formatted_string;
    };

    exports.getItemShippingCountry = function (item) {
        var result = '';

        if (typeof item.ships_from_country !== 'undefined' && item.ships_from_country.length > 0) {
            var title = (item.subject_typeid === SUBJECT_TYPE.wtb ? 'Destination ' : 'Ships from ') + item.ships_from_country;
            result = '<span class="flag flag-' + item.ships_from_country_code + '" data-toggle="tooltip" title="' + title + '"></span>';
        }

        return result;
    };

    // todo: remove ?
    /*
    exports.getStats = function (params, cb) {
        var filters = params.filters;
        var filter_str_listings = _data.parseFilterParams(filters, 'listing');
        var filter_str_parts = _data.parseFilterParams(filters, 'part');
        var results = {};

        _data.facetRunner(filter_str_listings, params.group_by, function (listings) {
            results.listings = listings;

            _data.facetRunner(filter_str_parts, params.group_by, function (parts) {
                results.parts = parts;
                cb(results);
            });

        });
    }
     */


    // todo: remove ?
    /*
    exports.facetRunner = function (filter_str, group_by, cb) {
        var resultSet = [];
        let facetJson = {
            results:
                {type: "terms",
                    field:group_by,
                    limit:1000,
                    sort:
                        {
                            quantity:"desc"
                        },
                    facet:
                        {
                            max_price:"max(price)",
                            min_price:"min(price)",
                            min_price_adjusted:"min(retail_price_aggregates)",
                            quantity:"sum(quantity)",
                            num_sellers:"unique(userid)"
                        }
                }
        };

        let query = Solr.Model.searchRaw('*:*');
        if(filter_str) {
            filter_str.forEach((str)=>{
                query.whereRaw(str);
            });
        }
        query.facet(facetJson)
            .groupBy(group_by)
            .orderBy('price','ASC')
            .get(1000,1)
    }
    */

    $('.show_more_records_trades_box').click(function () {
        var count_total_records = $(this).attr("count_total_records");

        if (count_total_records >= 100) {
            $('.first_100_shown').show();
        }

        if ($('.show_more_records_trades_box').html() == 'Show Less') {
            $(".show_more_records").addClass('hidden');
            if (count_total_records >= 100) {
                $('.first_100_shown').hide();
            }
            $('.show_more_records_trades_box').html('Show More');


            window.Analytics.trackInGA('TradesCountBox-show-less',{});

        } else if ($('.show_more_records_trades_box').html() == 'Show More') {
            $(".show_more_records").removeClass('hidden');
            if (count_total_records >= 100) {
                $('.first_100_shown').show();
            }
            $('.show_more_records_trades_box').html('Show Less');


            window.Analytics.trackInGA('TradesCountBox-show-more',{});
        }

        return false;
    });


    exports.refresh = function (callback, shouldResetPage) {

        if (shouldResetPage) {
            _data.filters.page = 1;
        }

        if (_data.filters.hasOwnProperty('search') && _data.filters.search !== "") {

            window.Analytics.track('search',{
                type_id: 48,
                eventData: {
                    search_term: _data.filters.search
                },
                localData: {
                    datavar: _data.filters.search
                }
            });
        }

        /*if (_data.filters.hasOwnProperty('categories') && _data.filters.categories !== null) {
            _data.filters.show_parts = 1;
        } else {
            _data.filters.show_parts = 0;
        }*/

        if (_data.rewrite_url) {
            _data.getFilterQuery();
        }

        document.dispatchEvent(new CustomEvent('tradesCountBox.update'));

        if (typeof callback === "function") {
            callback();
        }
    };

    exports.load_image = function (gid, image_path, image_alt) {
        var img = new Image();

        $(img)
            .on("load", function () {
                $('#item-' + gid).find('.listing__image-link').append(this);
            })
            .attr('src', image_path)
            .attr('alt', image_alt)
            .attr('width', '70')
            .attr('height', '70')
            .addClass('listing__image-thumb');
    };

    exports.post_render = function (gid, item) {
        var $item = $('#item-' + gid);

        if (_data.filters.hasOwnProperty('search') && _data.filters.search !== "") {
            var qsearch = _data.filters.search.toLowerCase();
            if(qsearch[0] === '"' && qsearch[qsearch.length - 1] == '"') {
                qsearch = qsearch.replace(/^"(.*)"$/, '$1');
            }
            doTextHighlight('subject' + gid, qsearch);
            doTextHighlight('message' + gid, qsearch);
            doTextHighlight('message' + gid + '-2', qsearch);
        }

        $item.find('[data-toggle="tooltip"]').tooltip({
            'trigger': 'hover'
        });
    };

    exports.format_price = function (price) {
        var fPrice = '';
        var strP = price.toString();
        var intP = parseFloat(strP);

        var inPFlt = intP.toFixed(2);
        var strPFlt = inPFlt.toString();
        var isFlZero = strPFlt.indexOf(".00");

        if (isFlZero !== -1) {
            fPrice = addCommas(intP);
        } else {
            fPrice = addCommas(intP.toFixed(2));
        }

        return fPrice;
    };

    exports.format_quantity = function(quantity, subject, targetPrice) {
        quantity = addCommas(quantity);

        if (subject === SUBJECT_TYPE.wtb) {
            return '<strong class="tl-color--wtb">' + quantity + '</strong> Needed'
        } else {
            if (parseInt(targetPrice) === TARGET_PRICE_TYPE.lot) {
                return 'Lot of <strong class="tl-color--wts">' + quantity + '</strong>';
            } else {
                return '<strong class="tl-color--wts">' + quantity + '</strong> Available';
            }
        }
    }


    exports.next_page = function () {


        window.Analytics?.track('5|3. Browse Listings',{
            type_id: 292,
            eventData: {
                "event_cat_id": 5,
                "event_cat_name": "3. Browse Listings",
                "tl_event_id": 292,
                "tl_event_name":"Clicked Show More",
                "tl_event_data":''
            },
            localData: {
                datavar: ''
            }
        });

        //Analytics?.trackInGA('Listings-ShowMore',{});
        _data.load_more_count++;
        if (_data.load_more_count >= 2 && !is_logged_in) {
            tradeloop.modal('register', function () {
            }, {type: 'main'}, 'show-more');
        } else {
            _data.filters.page++;
            document.dispatchEvent(new CustomEvent('listings.get', {}));
        }
    };


    exports.set_page = function (p) {

        window.Analytics?.track('5|3. Browse Listings',{
            type_id: 292,
            eventData: {
                "event_cat_id": 5,
                "event_cat_name": "3. Browse Listings",
                "tl_event_id": 292,
                "tl_event_name":"Clicked Show More",
                "tl_event_data":''
            },
            localData: {
                datavar: ''
            }
        });
        _data.load_more_count++;
        if (_data.load_more_count >= 2 && !is_logged_in) {
            tradeloop.modal('register', function () {
            }, {type: 'main'}, 'show-more');
        } else {
            _data.filters.page = p;
            document.dispatchEvent(new CustomEvent('listings.get', {}));
        }
    };

    exports.parseQueryFilters = function () {

        var str = "*:*"
        if (_data.filters.hasOwnProperty('search')) {
            var qsearch = "";
            qsearch = _data.filters.search.toLowerCase().replace(/\//gi, ' ');
            var now = Math.floor(Date.now() / 1000);
            str = '{!boost b=div(sorting_datetime,'+now+')}';

            if(qsearch.length > 2 && qsearch[0] === '"' && qsearch[qsearch.length - 1] == '"') {
                let searchTerm = qsearch.substring(0);
                searchTerm = searchTerm.substring(0, searchTerm.length - 1);
                const regex = /((?:^|[^\\])(?:\\{2})*)"/gm;
                const subst = `$1\\"`;
                searchTerm = searchTerm.replace(regex, subst);
                qsearch = '"'+searchTerm+'"';
            } else {
                const regex = /((?:^|[^\\])(?:\\{2})*)"/gm;
                const subst = `$1\\"`;
                qsearch = qsearch.replace(regex, subst);
            }

            if (qsearch.length > 2 && qsearch[0] === '"' && qsearch[qsearch.length - 1] == '"') {
                 str += 'combinedSearch:' + qsearch + '';
            } else {
                qsearch = qsearch.replace(/\-/g, ' ');
                var words = qsearch.split(' ');

                if (words.length > 1) {
                    let phrase = '"' + qsearch + '"';
                     str += '(combinedSearch:' + phrase + '^0.5';
                } else {
                     str += '(combinedSearch:(*' + qsearch + '* OR ' + qsearch + ')^0.5';
                }

                str += ' OR subject:(/^' + qsearch + '/)^4' +
                    ' OR subject:(' + qsearch + ')^3' +
                    ' OR description:(' + qsearch + ')^1.5' +
                    ' OR description:(*' + qsearch + '*)^1.1' +
                    ')';

            }

            var dates = {
                years: {
                    current: moment().format('YYYY'),
                    last: moment().subtract(1, 'year').format('YYYY'),
                    before_last: moment().subtract(2, 'year').format('YYYY')
                },
                months: {
                    current: moment().format('YYYY-MM'),
                    last: moment().subtract(1, 'month').format('YYYY-MM'),
                    before_last: moment().subtract(2, 'month').format('YYYY-MM')
                }
            };



        }

        return str;
    };

    exports.parseCategories = function (categories) {
        var filter = "";
        categories.forEach(function (cat1, iii) {
            filter += iii == 0 ? "(" : " OR (";

            if (cat1.hasOwnProperty('id') && cat1.hasOwnProperty('type') && cat1.type === 'cat_1_id') {
                if (cat1.hasOwnProperty('sub_categories') && cat1.sub_categories.length > 0) {
                    filter += "cat1id:" + cat1.id;
                    var cat2str = " AND ";

                    cat1.sub_categories.forEach(function (cat2, ii) {
                        cat2str += ii == 0 ? "" : " OR "
                        if (cat2.hasOwnProperty('id') && cat2.hasOwnProperty('type') && cat2.type === 'cat_2_id') {
                            if (cat2.hasOwnProperty('sub_categories') && cat2.sub_categories.length > 0) {
                                cat2str += "(cat2id:" + cat2.id + " AND cat3id:(";
                                cat2.sub_categories.forEach(function (cat3, i) {
                                    if (cat3.hasOwnProperty('id') && cat3.hasOwnProperty('type') && cat3.type === 'cat_3_id') {
                                        cat2str += (i == 0 ? "" : " OR ") + cat3.id;
                                    }
                                });
                                cat2str += "))";
                            } else {
                                cat2str += "cat2id:" + cat2.id + "";
                            }
                        }
                    });
                    filter += cat2str + ")";
                } else {
                    filter += "cat1id:" + cat1.id + ")";
                }
            }
        });
        return filter;
    }

    exports.getFilterQuery = function () {
        var filter_str = [];

        if (_data.filters.hasOwnProperty('categories')) {
            var cat_str = [];
            _data.filters.categories.forEach(function (cat1, iii) {
                if (cat1.hasOwnProperty('id') && cat1.hasOwnProperty('type') && cat1.type === 'cat_1_id') {
                    if (cat1.hasOwnProperty('sub_categories') && cat1.sub_categories.length > 0) {
                        cat1.sub_categories.forEach(function (cat2, ii) {
                            if (cat2.hasOwnProperty('id') && cat2.hasOwnProperty('type') && cat2.type === 'cat_2_id') {
                                if (cat2.hasOwnProperty('sub_categories') && cat2.sub_categories.length > 0) {
                                    cat2.sub_categories.forEach(function (cat3, i) {
                                        if (cat3.hasOwnProperty('id') && cat3.hasOwnProperty('type') && cat3.type === 'cat_3_id') {
                                            cat_str.push(cat1.id + ":" + cat2.id + ":" + cat3.id);
                                        }
                                    });
                                } else {
                                    cat_str.push(cat1.id + ":" + cat2.id);
                                }
                            }
                        });
                    } else {
                        cat_str.push(cat1.id);
                    }
                }
            });
            filter_str.push("c=" + cat_str.join('|'));
        }


        if (_data.filters.hasOwnProperty('replied_listings') && _data.filters.replied_listings.length > 0) {
            _data.filters.replied_listings = _data.filters.replied_listings.getUnique();
            filter_str.push("rp=" + _data.filters.replied_listings.join(','));
        }

        if (_data.filters.hasOwnProperty('created_by')) {
            filter_str.push("us=" + _data.filters.created_by);
        }

        if (_data.filters.hasOwnProperty('manufacturer_ids') && _data.filters.manufacturer_ids.length > 0) {
            _data.filters.manufacturer_ids = _data.filters.manufacturer_ids.getUnique();
            filter_str.push("m=" + _data.filters.manufacturer_ids.join(','));
        }

        if (_data.filters.hasOwnProperty('countries') && _data.filters.countries.length > 0) {
            _data.filters.countries = _data.filters.countries.getUnique();
            filter_str.push("co=" + _data.filters.countries.join(','));
        }

        if (_data.filters.hasOwnProperty('country_codes') && _data.filters.country_codes.length > 0) {
            _data.filters.country_codes = _data.filters.country_codes.getUnique();
            filter_str.push("co2=" + _data.filters.country_codes.join(','));
        }

        if (_data.filters.hasOwnProperty('condition_ids') && _data.filters.condition_ids.length > 0) {
            _data.filters.condition_ids = _data.filters.condition_ids.getUnique();
            filter_str.push("cd=" + _data.filters.condition_ids.join(','));
        }

        if (_data.filters.hasOwnProperty('brand_ids') && _data.filters.brand_ids.length > 0) {
            _data.filters.brand_ids = _data.filters.brand_ids.getUnique();
            filter_str.push("b=" + _data.filters.brand_ids.join(','));
        }

        if (_data.filters.hasOwnProperty('model_ids') && _data.filters.model_ids.length > 0) {
            _data.filters.model_ids = _data.filters.model_ids.getUnique();
            filter_str.push("md=" + _data.filters.model_ids.join(','));
        }

        if (_data.filters.hasOwnProperty('type_ids') && _data.filters.type_ids.length > 0) {
            _data.filters.type_ids = _data.filters.type_ids.getUnique();
            filter_str.push("t=" + _data.filters.type_ids.join(','));
        }

        if (_data.filters.hasOwnProperty('company_id') &&  _data.filters.company_id.length > 0) {
            _data.filters.company_id = _data.filters.company_id.getUnique();
            filter_str.push("cid=" + _data.filters.company_id.join(','));
        }

        if (_data.filters.hasOwnProperty('search')) {
            filter_str.push("s=1&k=" + _data.filters.search);
        }
        if (_data.filters.hasOwnProperty('type')) {
            filter_str.push("tp=" + _data.filters.type);
        }
        if (_data.filters.hasOwnProperty('show_parts')) {
            filter_str.push("sp=" + _data.filters.show_parts);
        }
        if (_data._show_member_listings && _data.filters.hasOwnProperty('displays_to_non_members')) {
            filter_str.push("dtnm=" + (_data.filters.displays_to_non_members ? 1 : 0).toString());
        }

        if (_data.filters.hasOwnProperty('selected_search')) {
            filter_str.push("ss=" + _data.filters.selected_search.id);
        }

        if (filter_str.length > 0) {
            filter_str.push("f=1");
        }
        if (filter_str.length > 0 && _data.update_url) {
            history.pushState({
                "filters": JSON.stringify(_data.filters)
            }, "Tradeloop | The Wholesale Marketplace for Used Electronics", window.location.href.split('?')[0] + '?' + filter_str.join('&'));
        } else if (_data.update_url) {
            history.pushState({
                "filters": JSON.stringify(_data.filters)
            }, "Tradeloop | The Wholesale Marketplace for Used Electronics", window.location.href.split('?')[0]);
        }
        _data.update_url = true;
        return filter_str;
    }

    exports.parseFilters = function () {
        return _data.parseFilterParams(_data.filters, 'listing');
    };

    exports.parseFilterParams = function (params, core, x) {
        var filter_str = [];
        var extra = Object.assign({}, x) || {};

        if (params.hasOwnProperty('type') && core !== 'part') {
            filter_str.push("subject_typeid:" + params.type);
        }

        if (params.hasOwnProperty('target_type') && core !== 'part') {
            filter_str.push("target_price_typeid:" + params.target_type);
        }

        if (params.hasOwnProperty('created_by')) {
            filter_str.push("userid:" + params.created_by);
        }

        if (params.hasOwnProperty('user_id')) {
            filter_str.push("userid:" + params.user_id);
        }

        if (params.hasOwnProperty('company_id') && params.company_id.length > 0) {
            params.company_id = params.company_id.getUnique();
            filter_str.push('companyid:(' + params.company_id.join(' OR ') + ')');
        }

        if (params.hasOwnProperty('is_major')) {
            filter_str.push("is_major:" + params.is_major);
        }

        if (core) {
            filter_str.push("core:" + core);
        }

        if (core === 'listing') {
            filter_str.push("is_maint_only:false");
            filter_str.push("should_display_profile:true");
            //filter_str.push("is_expired:false");
        }

        filter_str.push("is_hidden:false");

        if (params.hasOwnProperty('created_by_company')) {
            filter_str.push("companyid:" + params.created_by_company);
        }

        if (params.hasOwnProperty('categories')) {
            var str = _data.parseCategories(params.categories);
            filter_str.push(str);
        }

        if (params.hasOwnProperty('replied_listings')) {

            if (params.replied_listings.length > 0) {
                params.replied_listings = params.replied_listings.getUnique();

                var str = "id:(";
                $.each(params.replied_listings, function (i, tfid) {
                    str += (i == 0 ? "" : " OR ");
                    str += "listing" + tfid;
                });
                str += ")";

                filter_str.push(str);
            } else {
                filter_str.push("tradefeed_id:(0)");
            }
        }

        if (params.hasOwnProperty('favorites_only') && params.favorites_only == 1 && _data.favorites.length > 0) {
            var str = "(core:listing AND id:(";
            $.each(_data.favorites, function (i, fid) {
                str += (i == 0 ? "" : " OR ");
                str += fid;
            });
            str += "))";
            filter_str.push(str);
        }

        if (params.hasOwnProperty('manufacturer_ids') && params.manufacturer_ids.length > 0) {

            params.manufacturer_ids = params.manufacturer_ids.getUnique();

            var str = "manu_id:(";
            var atleastOne = false;

            $.each(params.manufacturer_ids, function (i, cid) {
                if (cid && cid !== '' && cid !== 'undefined' && !isNaN(cid)) {
                    str += (i == 0 ? "" : " OR ");
                    atleastOne = true;
                    if (Math.sign(cid) === -1) {
                        str += '"' + cid + '"'; 
                    } else {
                        str += cid; 
                    }
                }
            });
            str += ")";

            if (atleastOne) {
                filter_str.push(str);
            } else {
                if (extra && extra.hasOwnProperty('get_all_manus') && extra.get_all_manus == 1) {
                    var str = "manu_id:[* TO *] -manu_id:0";
                    filter_str.push(str);
                }
            }
        } else {
            if (extra && extra.hasOwnProperty('get_all_manus') && extra.get_all_manus == 1) {
                var str = "manu_id:[* TO *] -manu_id:0";
                filter_str.push(str);
            }
        }


        if (params.hasOwnProperty('countries') && params.countries.length > 0) {

            params.countries = params.countries.getUnique();

            var str = "region_name:(";
            $.each(params.countries, function (i, cid) {
                str += (i == 0 ? "" : " OR ");
                str += cid;

            });
            str += ")";
            filter_str.push(str);
        }


        if (params.hasOwnProperty('condition_ids') && params.condition_ids.length > 0) {

            params.condition_ids = params.condition_ids.getUnique();

            var str = "condition_id:(";
            $.each(params.condition_ids, function (i, cid) {
                str += (i == 0 ? "" : " OR ");
                str += cid;
            });
            str += ")";
            filter_str.push(str);
        }

        if (params.hasOwnProperty('brand_ids') && params.brand_ids.length > 0) {
            params.brand_ids = params.brand_ids.getUnique();
            var str = "brand_id:(";
            $.each(params.brand_ids, function (i, cid) {
                str += (i == 0 ? "" : " OR ");
                str += cid;
            });
            str += ")";
            filter_str.push(str);
        }

        if (params.hasOwnProperty('model_ids') && params.model_ids.length > 0) {
            params.model_ids = params.model_ids.getUnique();
            var str = "model_id:(";
            $.each(params.model_ids, function (i, cid) {
                str += (i == 0 ? "" : " OR ");
                str += cid;
            });
            str += ")";
            filter_str.push(str);
        }

        if (params.hasOwnProperty('type_ids') && params.type_ids.length > 0) {
            params.type_ids = params.type_ids.getUnique();
            var str = "catalog_model_types_id:(";
            $.each(params.type_ids, function (i, cid) {
                str += (i == 0 ? "" : " OR ");
                str += cid;
            });
            str += ")";
            filter_str.push(str);
        }

        if (params.hasOwnProperty('specifications')) {
            var specs = params.specifications;
            $.each(specs, function (i, v) {
                if (v.length > 0) {
                    var str = encodeURI(i) + ':("';
                    str += v.join('" OR "');
                    str += '")';
                }
                filter_str.push(str);
            });

        }

        if (params.hasOwnProperty('country_codes') && params.country_codes.length > 0) {
            params.country_codes = params.country_codes.getUnique();
            filter_str.push('ships_from_country_code:(' + params.country_codes.join(' OR ') + ')');
        }

        if (params.hasOwnProperty('displays_to_non_members')) {
            filter_str.push('displays_to_non_members:' + params.displays_to_non_members.toString());
        }

        return filter_str;
        //return "&fq="+filter_str.join("&fq=");
    };


    exports.add_row = function (id, content, cls) {
        var list = $('.autocomplete-box');
        list.append('<li class="' + cls + '" data-id="' + id + '">' + content + '</li>');

    };

    exports.clean = function (txt) {
        var re = new RegExp(String.fromCharCode(160), "g");
        txt = txt.replace(re, " ");
        txt = txt.replace(/(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi, '[email witheld]');
        return txt;
    };

    exports.get_filters = function () {
        framework.post(url('api/get_filters'), {
            _token: $('input[name="_token"]:first').val()
        }, function (r) {
        });
    };

    exports.hasReplied = function (id) {
        if (typeof replied != 'undefined') {
            if (replied.hasOwnProperty(id + "")) {
                return true;
            }
        }
        return false;
    };

    exports.replyId = function (id) {
        if (typeof replied != 'undefined') {
            if (replied.hasOwnProperty(id + "")) {
                return replied[id + ""];
            }
        }
        return {
            "message_id": "",
            "created_at": ""
        };


    };

    exports.replyCount = function (id) {
        if (typeof created != 'undefined') {
            if (created.hasOwnProperty(id + "")) {
                return created[id + ""]['replies'];
            }
        }
        return 0;
    };

    exports.remove_saved_search = function () {
        if (_data.filters.hasOwnProperty('selected_search')) {
            delete _data.filters.selected_search;
        }

        $('.saved-search-filter').each(function () {
            $(this).prop('checked', false);
        });
    };

    exports.generate_page_links = function () {

        var itemsPerPage = 50;
        if (typeof _itemsPerPage !== 'undefined') {
            itemsPerPage = _itemsPerPage;
        }


        $('.pagination-container').find('.pagination').remove();
        var total_pages = Math.ceil(_data.numFound / itemsPerPage);
        var current_page = parseInt(_data.filters.page);
        var rel_next_str = '';
        var rel_prev_str = '';
        var relprevpagePath = '';
        var relnextpagePath = '';
        var rel_next_page = '';
        var rel_prev_page = '';
        var page_counter_text = 'Page ' + current_page + ' of ' + total_pages;

        var temp_host_name = window.location.hostname;
        temp_host_name = temp_host_name.replace("public", "www");


        if (isNaN(current_page)) {
            current_page = 1;
        }

        if (current_page == total_pages) {
            page_counter_text = 'Page ' + current_page;
        }


        if (typeof _append_page_title != 'undefined') {
            if (current_page > 1) {
                $("title").prepend(page_counter_text + ' of');
                $("#tl-page-of").html(page_counter_text);
            }
        }

        var str = '<ul class="pagination">';


        if (current_page > 1) {
            var dptp = docPath;
            var pagePath = '';
            var firstPagePath = '';

            dptp.removeFolderAndParam('page');
            dptp.removeFolder('pg');
            //dptp.urlParams.delete('page');
            rel_prev_page = current_page - 1;
            firstPagePath = dptp.fullDocPath();

            if (rel_prev_page === 1) {
                pagePath = firstPagePath;
            } else {
                dptp.urlParams.set('page', rel_prev_page);
                pagePath = dptp.fullDocPathWithFolder('pg/'+rel_prev_page);
            }

            if (total_pages > 5) {
                str += '<li class="first-page"><a href="' + firstPagePath + '"><i class="fas fa-angle-double-left"></i></a></li>';
                str += '<li class="prev-page"><a href="' + pagePath + '"><i class="fas fa-angle-left"></i></a></li>';
            }
        }


        var i = 0;
        var f = 0;
        var dpcp = docPath;
        dpcp.removeFolderAndParam('page');
        dpcp.removeFolder('pg');


        for (i = 0; i < 5; i++) {
            f = current_page - 2 + i;

            if (f > 0) {
                if (f == current_page) {
                    str += '<li class="active"><span>' + f + '</span></li>';
                } else {
                    var pagePath = '';
                    if (f === 1) {
                        dpcp.urlParams.delete('page');
                        pagePath = dpcp.fullDocPath();
                    } else {
                        dpcp.urlParams.set('page', f);
                        pagePath = dpcp.fullDocPathWithFolder('pg/'+f);
                    }

                    if (f <= total_pages) {
                        str += '<li><a href="' + pagePath + '">' + f + '</a></li>';
                    }
                }

            }
        }


        var z = Math.ceil((total_pages - f) / 2);
        var y = total_pages - 5;
        var control = f;
        var dpxp = docPath;
        dpxp.removeFolderAndParam('page');
        dpxp.removeFolder('pg');

        var dpprev = docPath;
        dpprev.removeFolderAndParam('page');
        dpprev.removeFolder('pg');

        var dpnext = docPath;
        dpnext.removeFolderAndParam('page');
        dpnext.removeFolder('pg');

        var prevPagePath = '';
        dpprev.urlParams.set('page', (f + 1));
        prevPagePath = dpprev.fullDocPathWithFolder('pg/'+(f+1));
        str += '<li><a href="'+prevPagePath+'"><b>...</b></a></li>';

        if (z > 0 && (total_pages - (f + z)) > 6) {
            var pagePath = '';
            dpxp.urlParams.set('page', (f + z));
            pagePath = dpxp.fullDocPathWithFolder('pg/'+(f+z));

            var nextPagePath = '';
            dpnext.urlParams.set('page', (f + z + 1));
            nextPagePath = dpnext.fullDocPathWithFolder('pg/'+(f+z+1));

            if (z < total_pages) {
                str += '<li><a href="' + pagePath + '">' + (f + z) + '</a></li>';
                str += '<li><a href="'+nextPagePath+'"><b>...</b></a></li>';
            }
            control = f + z;
        } else {
            if (y > f) {
                str += '<li><a href="'+prevPagePath+'"><b>...</b></a></li>';
            }
        }


        var dpyp = docPath;
        dpyp.removeFolderAndParam('page');
        dpyp.removeFolder('pg');
        for (i = 5; i > 0; i--) {
            y = total_pages - i;
            if (y > control) {
                var pagePath = '';
                dpyp.urlParams.set('page', y);
                pagePath = dpyp.fullDocPathWithFolder('pg/'+y);
                if (y < total_pages) {
                    str += '<li><a href="' + pagePath + '">' + y + '</a></li>';
                }
            }
        }


        if (current_page <= total_pages) {
            var dplp = docPath;
            var dlNext = docPath;
            var dlPrev = docPath;
            var dpLast = docPath;
            var pagePath = '';
            var lastPagePath = '';
            var pp = (current_page + 1);

            dplp.removeFolderAndParam('page');
            dplp.removeFolder('pg');
            dlNext.removeFolderAndParam('page');
            dlNext.removeFolder('pg');
            dlPrev.removeFolderAndParam('page');
            dlPrev.removeFolder('pg');
            dpLast.removeFolderAndParam('page');
            dpLast.removeFolder('pg');

            rel_next_page = current_page + 1;
            rel_prev_page = current_page - 1;

            if (rel_prev_page === 1) {
                relprevpagePath = dlPrev.fullDocPath();
            } else {
                dlPrev.urlParams.set('page', rel_prev_page);
                relprevpagePath = dlPrev.fullDocPathWithFolder('pg/'+rel_prev_page);
            }

            dlNext.urlParams.set('page', rel_next_page);
            relnextpagePath = dlNext.fullDocPathWithFolder('pg/'+rel_next_page);

            dpLast.urlParams.set('page', total_pages);
            lastPagePath = dpLast.fullDocPathWithFolder('pg/'+total_pages);

            dplp.urlParams.set('page', pp);
            pagePath = dplp.fullDocPathWithFolder('pg/'+pp);

            //var st=dplp.fullDocPath().substring(0, dplp.fullDocPath().indexOf("?"));
            // st = st.trim();


            if (rel_prev_page != 0) {
                rel_prev_str = '<link rel="prev" href= "https://' + temp_host_name + '' + relprevpagePath + '">';
            }

            if (rel_next_page <= total_pages) {
                rel_next_str = '<link rel="next" href= "https://' + temp_host_name + '' + relnextpagePath + '">';
            }

            if (current_page < total_pages && total_pages > 5) {
                str += '<li><a href="' + pagePath + '"><i class="fas fa-angle-right"></i></a></li>';
                str += '<li class="last-page"><a href="' + lastPagePath + '"><i class="fas fa-angle-double-right"></i></a></li>';
            }
        }
        str += '</ul>';


        $('.pagination-container').append(str);
        $('head').append(rel_prev_str);
        $('head').append(rel_next_str);

    }

    exports.dispatchPopulateFiltersEvent = function() {
        document.dispatchEvent(new CustomEvent('filter.populateFilters', {detail: {data: _data.filters}}));
    };

    exports.hasCategoryFilter = function() {
        return _data.filters.hasOwnProperty('categories') &&
            _data.filters.categories !== null &&
            _data.filters.categories.length > 0;
    };

    return exports;

})();

$(document).ready(function () {
    _data.init();


    $.fn.popover.Constructor.prototype.reposition = function () {
        var $tip = this.tip()

        var placement = typeof this.options.placement === 'function' ? this.options.placement.call(this, $tip[0], this.$element[0]) : this.options.placement

        var autoToken = /\s?auto?\s?/i
        var autoPlace = autoToken.test(placement)
        if (autoPlace) placement = placement.replace(autoToken, '') || 'top'

        var pos = this.getPosition()
        var actualWidth = $tip[0].offsetWidth
        var actualHeight = $tip[0].offsetHeight - 1

        if (autoPlace) {
            var orgPlacement = placement
            var viewportDim = this.getPosition(this.$viewport)

            placement = placement == 'bottom' && pos.bottom + actualHeight > viewportDim.bottom ? 'top' :
                placement == 'top' && pos.top - actualHeight < viewportDim.top ? 'bottom' :
                    placement == 'right' && pos.right + actualWidth > viewportDim.width ? 'left' :
                        placement == 'left' && pos.left - actualWidth < viewportDim.left ? 'right' :
                            placement

            $tip
                .removeClass(orgPlacement)
                .addClass(placement)
        }

        var calculatedOffset = this.getCalculatedOffset(placement, pos, actualWidth, actualHeight)

        this.applyPlacement(calculatedOffset, placement)
    }

});
